import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <Array<RouteRecordRaw>>[
  {
    path: '/login',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/Application/Login/Index.vue'),
    meta: { hidden: true, title: '用户登录' }
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '首页', icon: 'dashboard' },
    redirect: '/Home',
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import( /* webpackChunkName: 'Home' */ '@/views/Application/Home/Index.vue'),
        meta: { title: '首页', icon: 'dashboard', sider_is_close: false }
      }
    ]
  }


]