import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/Configure',
  component: Layout,
  redirect: '/Home',
  name: 'Configure',
  meta: { title: '开发', icon: 'lock', hidden: true },
  children: [
    {
      path: '/Configure/FineReport',
      component: () => import(/* webpackChunkName: "Configure" */ '@/views/Configure/FineReport/Index.vue'),
      meta: { title: '报表配置', icon: 'el-icon-menu', roles: [], sider_is_close: false ,hidden: true }
    },
    {
      path: '/Configure/MobileReport',
      component: () => import(/* webpackChunkName: "Configure" */ '@/views/Configure/MobileReport/Index.vue'),
      meta: { title: '报表配置', icon: 'el-icon-menu', roles: [], sider_is_close: false ,hidden: true }
    }
  ]
}