import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { NConfigProvider } from 'naive-ui'
import { zhCN, dateZhCN } from 'naive-ui'



export default _defineComponent({
  setup(__props) {

const locale = zhCn


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(ElConfigProvider), { locale: _unref(locale) }, {
    default: _withCtx(() => [
      _createVNode(_unref(NConfigProvider), {
        locale: _unref(zhCN),
        "date-locale": _unref(dateZhCN)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale", "date-locale"])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})