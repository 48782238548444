
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { APPModule } from '@/store/modules/app';
import { PermissionModule } from '@/store/modules/permission'
export default defineComponent({
    setup () {

        const route = useRoute();
        const router = useRouter();

        const routes = computed(() => {
            const [subRoutes] = PermissionModule.routes.filter(r => r.path === APPModule.navbar_active)
            if (subRoutes && subRoutes.children) {
                return subRoutes.children.filter(r => !r.meta?.hidden)
            }
        })

        const handleSelect = (key: any, keyPath: any) => {
            if (key === route.path) { return }
            router.push({ path: key })
        };


        const activeIndex = computed(() => {
            const { meta, path } = route
            if (meta?.activeMenu) { return meta.activeMenu }
            return path
        })

        return {
            sidebar_is_close: computed(() => APPModule.sidebar_is_close),
            routes,
            activeIndex,
            handleSelect,
        }
    },
    components: {

    },
});
