import request from "./request";

export function wxConfig (url: string, jsApiList: string[]) {

  return new Promise((resolve, reject) => {

    // request('/wxconfig', { url }, { method: 'POST', module: '/work/jssdk' }).then(response => {
    //   console.log('response')
    //   console.log(response)
    //   wx.config({
    //     beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: response.result.appId, // 必填，企业微信的corpID
    //     timestamp: response.result.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: response.result.nonceStr, // 必填，生成签名的随机串
    //     signature: response.result.signature,// 必填，签名，见附录1
    //     jsApiList: ['selectEnterpriseContact'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //   });
    //   wx.ready(() => {
    //     console.log('WXConfig加载成功')
    //   });
    // })

  })

}


export function agentConfig (url: string, jsApiList: string[]) {

  return new Promise((resolve, reject) => {

    request('/auth/jssdk/agentConfig', { url }).then(response => {

      wx.agentConfig({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        corpid: response.data.corpid, // 必填，企业微信的corpID
        agentid: response.data.agentid,                // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: response.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
        signature: response.data.signature,// 必填，签名，见附录1
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        success: (res: any) => { resolve(wx); },
        fail: (err: any) => { reject(err); }
      });

    })

  })

}