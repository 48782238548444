
import { defineComponent, ref } from 'vue';
import { APPModule } from '@/store/modules/app';
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'
export default defineComponent({
    components: {
        Navbar, Sidebar, Footer
    },
    setup () {

        return {
            copyright: APPModule.copyright,
        }
    }
});
