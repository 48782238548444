import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_footer = _resolveComponent("el-footer");

  return _openBlock(), _createBlock(_component_el_footer, {
    height: "40",
    style: {
      "color": "#FFFFFF"
    }
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("Copyright @ " + _toDisplayString(_ctx.copyright), 1)];
    }),
    _: 1
  });
}