import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu_item = _resolveComponent("el-menu-item");

  var _component_el_menu = _resolveComponent("el-menu");

  var _component_el_aside = _resolveComponent("el-aside");

  return !_ctx.sidebar_is_close ? (_openBlock(), _createBlock(_component_el_aside, {
    key: 0,
    style: {
      "width": "200px"
    }
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_menu, {
        "default-active": _ctx.activeIndex,
        onSelect: _ctx.handleSelect,
        style: {
          "height": "100%"
        }
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.routes, function (item) {
            return _openBlock(), _createBlock(_component_el_menu_item, {
              index: item.path,
              key: item.path
            }, {
              title: _withCtx(function () {
                return [_createTextVNode(_toDisplayString(item.meta.title), 1)];
              }),
              "default": _withCtx(function () {
                return [_createVNode("i", {
                  "class": item.meta.icon
                }, null, 2)];
              }),
              _: 2
            }, 1032, ["index"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["default-active", "onSelect"])];
    }),
    _: 1
  })) : _createCommentVNode("", true);
}