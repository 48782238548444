import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "common-layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Navbar = _resolveComponent("Navbar");

  var _component_Sidebar = _resolveComponent("Sidebar");

  var _component_router_view = _resolveComponent("router-view");

  var _component_el_main = _resolveComponent("el-main");

  var _component_el_container = _resolveComponent("el-container");

  var _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
    style: {
      "height": "100vh"
    },
    direction: "vertical"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_Navbar), _createVNode(_component_el_container, null, {
        "default": _withCtx(function () {
          return [_createVNode(_component_Sidebar), _createVNode(_component_el_container, null, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_main, {
                style: {
                  "height": "calc(100vh - 101px)"
                }
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_router_view)];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_Footer)];
    }),
    _: 1
  })]);
}