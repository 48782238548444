import router from '@/router'
import token from '@/plugin/token'

import { RouteLocationNormalized } from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { UserModule } from "@/store/modules/user";
import { PermissionModule } from "@/store/modules/permission"
import { APPModule } from '@/store/modules/app';


import { wxConfig } from '@/plugin/work'

NProgress.configure({ showSpinner: false })

// 白名单
const whiteList = ["/Login", "/LoginWork"];

router.beforeEach(async (to, from, next) => {

  NProgress.start() // 进度条开始

  // 在白名单中的判断情况
  const hasToken = token.get()

  if (hasToken) {  // 如果 Token 存在

    // 如果是在企业微信内打开的
    if (/wxwork/.test(navigator.userAgent)) {
      // wxConfig(location.origin + to.fullPath, ["innerSaveWebPerformance",'selectEnterpriseContact'])
      wxConfig(location.origin + to.fullPath, ["innerSaveWebPerformance", 'selectEnterpriseContact'])
    }

    if (to.path === '/Login') { // 访问路径是 Login

      next({ path: '/' })
      NProgress.done()

    } else {

      const hasRoles = UserModule.roles && UserModule.roles.length > 0
    
      if (hasRoles) { // 如果角色列表不为空
   
        if (to.meta && to.meta.sider_is_close === false) {
          APPModule.SET_SIDEBAR_CLOSED(!to.meta.sider_is_close)
        } else {
          APPModule.SET_SIDEBAR_CLOSED(false)
        }

        next()

      } else { // 角色列表为空

        try { // 尝试获取用户 UserInfo

          await UserModule.GetUserInfo();

          const accessedRoutes: any = await PermissionModule.GenerateRoutes(UserModule.roles);

          for (const access of accessedRoutes) {
           router.addRoute(access)
          }

          next({ ...to, replace: true })

        } catch (error) { // 获取失败
          console.log(error)
          UserModule.LogOut()
          location.reload()

        } finally {
          NProgress.done()
        }

      }

    } 


  } else { // 如果 Token 不存在


    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/Login`)
      NProgress.done()
    }

  }

})

router.afterEach((to: RouteLocationNormalized) => {
  NProgress.done() // 进度条结束
  document.title = to.meta.title   // 修改网页标签名称
})